import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then(m => m.HomePageModule)
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'pnr-web2-movie-studio',
    loadChildren: () => import('./ui/pnr-web2-movie-studio/pnr-web2-movie-studio.module').then(m => m.PnrWeb2MovieStudioPageModule)
  },
  {
    path: 'tv-scan-me-page',
    loadChildren: () => import('./ui/tv-scan-me-page/tv-scan-me-page.module').then(m => m.TvScanMePagePageModule)
  },
  {
    path: 'manage-scanned-tv-content',
    loadChildren: () => import('./ui/manage-scanned-tv-content/manage-scanned-tv-content.module').then(m => m.ManageScannedTvContentPageModule)
  },
  {
    path: 'list-available-screens-page',
    loadChildren: () => import('./ui/list-available-screens-page/list-available-screens-page.module').then(m => m.ListAvailableScreensPagePageModule)
  },
  {
    path: 'pnr-web2-movie-image-step-editor-page',
    loadChildren: () => import('./ui/pnr-web2-movie-image-step-editor-page/pnr-web2-movie-image-step-editor-page.module').then(m => m.PnrWeb2MovieImageStepEditorPagePageModule)
  },
  {
    path: 'live',
    loadChildren: () => import('./ui/live/live.module').then(m => m.LivePageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
